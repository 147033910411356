import { useRouteError, isRouteErrorResponse } from '@remix-run/react';

export function DefaultErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Sorry, we{"'"}ve run into a problem.</h1>
        <p>Status: {error.status}</p>
        <p>{error.data.message}</p>
      </div>
    );
  }

  let errorMessage = 'Unknown error';
  if (error instanceof Error) {
    errorMessage = error.message;
    if (
      typeof document !== 'undefined'
        ? (window as { ENV?: { NODE_ENV: string } })?.ENV?.NODE_ENV ===
          'development'
        : process.env.NODE_ENV === 'development'
    ) {
      console.error(error);
    }
  }

  return (
    <div>
      <h1>Uh oh ...</h1>
      <p>Something went wrong.</p>
      <pre>{errorMessage}</pre>
    </div>
  );
}
